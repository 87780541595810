import { Box, Breadcrumbs, Container, Link } from '@material-ui/core'
import BaseLayout from 'components/BaseLayout'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { graphql } from 'gatsby'
import React from 'react'
import rehypeReact from "rehype-react"

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { 
    "a": Link
  }
}).Compiler

export default function BlogPost({ data }) {
  const post = data.markdownRemark
  return (
    <BaseLayout title={post.frontmatter.title}>
      <Header />


      <Container maxWidth="sm">
        {post?.frontmatter?.breadcrumbs &&
          <Breadcrumbs aria-label="navigation">
            <Link color="inherit" href="/" >
              Home
            </Link>
            {post?.frontmatter?.breadcrumbs?.map((b,i) => 
              <Link color="inherit" href={b.link} key={`link${i}`}>
                {b.title}
              </Link>
            )}
          </Breadcrumbs>
        }

        <Box pb={4}>
          {renderAst(post.htmlAst)}
        </Box>
      </Container>

      <Footer/>

    </BaseLayout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      fields {
        slug
      }
      frontmatter {
        title
        breadcrumbs {
          link
          title
        }
      }
    }
  }
`